const STORAGE_PRE_KEY = "@MK-WEBINAR";

const generateKey = (key: string) => `${STORAGE_PRE_KEY}-${key}`;

const userStorage = {
  save: (values: any, key: string) => {
    localStorage.setItem(generateKey(key), JSON.stringify(values));
  },

  load: (key: string): any => {
    const values = localStorage.getItem(generateKey(key));
    
    if (values) {
      return JSON.parse(values);
    }

    return values;
  },

  clear: () => {
    localStorage.clear();
  },
};

export default userStorage;
