import styles from "./Home.module.scss"

export default function Home() {

  return (
    <>
      <div
        className={styles.banner}
      >
        <div className={styles.container}>
          <div className={`${styles.description} fade-in`}>
            <h1 className={styles.description_name}>
              Webinar para Consultores de Beleza
            </h1>
            <img
              alt="#"
              className={styles.logo}
              src="https://static.eventials.com/media/e5b0b7482c832dc425adf14e48c877341e176236/008451a05e1e7aa32c75119df950d405265e0904/1666635621/aprendamk.png "
            />

            <div className={styles.description_date}>
              EM BREVE
            </div>

          </div>
        </div>
      </div>
    </>
  )


}