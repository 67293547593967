import { useState, useEffect } from "react";

import SubscribeForm from "./subscribe.form";
import styles from "./DynamicForm.module.scss"

export default function RenderForm({ event }: any) {
  const [values, setValues] = useState<any>({
    email: "",
  });

  useEffect(() => {
    if (!event.webinar.is_public) {
      setValues({ ...values, access_code: "" });
    }

  }, [event])


  const handleChange = (field: string, aditionalField?: string) => (e: any) => {
    setValues({ ...values, [field]: e.target.value, ...(aditionalField ? { [aditionalField]: e.target.value } : {}) });
  };


  return (
    <div className={styles.form_container}>

      <SubscribeForm {...{
        handleChange,
        values,
        event,
      }} />

    </div>
  )
}
