
export function renderPNGImage() {
    const userAgent = window.navigator.userAgent;

    if (userAgent.match(/edg/i)) {
        return true;
    } else if (userAgent.match(/safari/i) && !userAgent.match(/chrome|chromium|crios/i)) {
        return true;
    } else if (userAgent.match(/msie|trident/i)) {
        return true;
    } else if (userAgent.match(/opera|opr/i)) {
        return true;
    } else if (userAgent.match(/firefox|fxios/i)) {
        return false;
    } else if (userAgent.match(/chrome|chromium|crios/i)) {
        return false;
    } else {
        return false;
    }
}