
import { useEffect } from 'react';
import './App.css';
// import * as gtag from "../src/lib/gtag"
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Home from './pages/Home';
import Form from './pages/Form';
import Watch from './pages/Watch';
import mkAVIF from './images/mk.avif';
import mkPNG from './images/mk.png';
import { renderPNGImage } from './lib/utils/renderPNGImage';

function App() {

  const routes = createBrowserRouter([
    {
      path: "",
      element: <Home />,
    },
    {
      path: "/:owner/:slug",
      element: <Form />,
    },
    {
      path: "/:owner/:slug/assistir",
      element: <Watch />,
    }
  ]);



  // useEffect(() => {
  //   const handleRouteChange = (url: URL) => {
  //     gtag.pageview(url);
  //   };
  //   navigate.events.on("routeChangeComplete", handleRouteChange);
  //   return () => {
  //     navigate.events.off("routeChangeComplete", handleRouteChange);
  //   };
  // }, [navigate.events]);

  return (
    <div className="App" style={{ backgroundImage: `url(${renderPNGImage() ? mkPNG : mkAVIF})` }}>
      <RouterProvider router={routes} />
    </div>
  );
}

export default App;
