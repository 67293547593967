import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { EventData } from "../../lib/api/event";
import LoadView from "../../lib/loading";
import userStorage from "../../lib/storage/user";
import styles from "./Form.module.scss";
import RenderForm from "../../components/form";
import { fetchEvent } from "../../lib/api";
import logo1AVIF from "../../images/logo1.avif"
import logo1PNG from "../../images/logo1.png"
import logo2AVIF from "../../images/logo2.avif"
import logo2PNG from "../../images/logo2.png"
import { renderPNGImage } from "../../lib/utils/renderPNGImage";

export interface GroupPageProps {
  event: EventData | null;
  loaded: boolean;
}

export interface GetStaticParamsProps {
  group: string;
  [key: string]: string | string[] | undefined;
}


const Form = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { owner, slug } = useParams();
  const [event, setEvent] = useState<any | null>(null);
  const [text, setText] = useState({
    title: "Carregando...",
    date: "Em breve",
  });

  useEffect(() => {
    getEvent()
  }, [slug])

  async function getEvent() {
    if (!slug) return

    await fetchEvent(`webinar`, {
      owner,
      slug
    }).then((res) => {
      setEvent(res)
    })
  }

  const loggedUser = userStorage.load(slug as string);


  useEffect(() => {
    if (event) {
      setLoading(false);
    }


    if (!event || !loggedUser) return;

    if (loggedUser && loggedUser.email) {
      navigate(`/${owner}/${slug}/assistir`);
      return;
    }

    let title = "";
    let date = "Em breve";
    if (event) {
      const d = new Date(event.webinar.start_time);
      date = d.toLocaleDateString("pt-BR", {
        day: "numeric",
        month: "long",
        year: "numeric",
        weekday: "long",
      });
      date += ` às `;
      date += d.toLocaleTimeString("pt-BR", {
        hour: "numeric",
        minute: "numeric",
      });

      title = event.webinar.title;
    }

    setText({ title, date });
  }, [event, loggedUser]);


  if (loading) {
    return <LoadView />;
  }

  return (
    <div className={styles.container}>
      <title>{text.title}</title>
      <img
        className={`${styles.description_image} ${styles.fixed_banner}  slide-from-left`}
        src={renderPNGImage() ? logo1PNG : logo1AVIF}
        alt="#"
      />
      <img
        className={styles.logo2}
        src={renderPNGImage() ? logo2PNG : logo2AVIF}
        alt="#"
      />
      <div id="background-form" />

      <div className={styles.form}>

        {event && <RenderForm event={event} />}
      </div>
    </div>
  );
};

export default Form;
