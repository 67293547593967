import { evtsFetch, FetchTypes } from ".";

export interface SubscribeData { }

export interface LoginData { }

const authApi = {
  subscribe: (values: SubscribeData, isPublic: boolean) => {
    return evtsFetch(FetchTypes.POST, "subscribe", values, isPublic);
  },

  login: (values: LoginData, formId: number) => {

    return evtsFetch(FetchTypes.POST, "login", { ...values, form_id: formId });
  },
};

export default authApi;
