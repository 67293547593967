import styles from "./style.module.css";
import { Hearts } from "react-loader-spinner";
import logo3 from "../../images/logo3.png"

export default function LoadView() {
  return (
    <>
      <div className={styles.load}>
        <img
          className={styles.load_logo}
          alt="#"
          src={logo3}
        />
        <Hearts

          color="#a74a78"
          height={44}
          width={44}

        />
      </div>
    </>
  );
}
