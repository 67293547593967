import InputMask from "react-input-mask";
import styles from "./DynamicForm.module.scss";
import React from "react";

export default function GroupInput({ label, ...inputProps }: any) {
  return (
    <div className={styles.form_group} key={label}>
      <label className={styles.form_group_name} key={label} htmlFor={inputProps.name}>
        {label}
      </label>
      <input
        id={inputProps.name}
        key={inputProps.name}
        className={styles.form_group_input}
        {...inputProps}
      />
    </div>
  );
}

export function SelectInput({label, ...inputProps}: any){
    return(
        <div className={styles.form_group} key={label}>
            <label className={styles.form_group_name} key={label} htmlFor={inputProps.name}>
                {inputProps.placeholder}
            </label>
            <select
                id={inputProps.name}
                key={inputProps.name}
                name={inputProps.name}
                className={styles.form_group_input}
                {...inputProps}
            >
                {inputProps.options.map((op: any) => {
                    return <option key={op.value} value={op.value}>{op.label}</option>
                })}
            </select>
        </div>
    )
}

export function GroupInputMask({ label, ...inputProps }: any) {
  return (
    <div className={styles.form_group} key={label}>
      <label className={styles.form_group_name} key={label} htmlFor={inputProps.name}>
        {label}
      </label>
      <InputMask
        id={inputProps.name}
        key={inputProps.name}
        className={styles.form_group_input}
        {...inputProps}
      />
    </div>
  );
}
