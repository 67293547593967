import styles from "./Asset.module.scss";

interface IProps {
  name: string;
  url: string;
}

export const Asset = ({url, name}: IProps) => {
  return(
    <div className={styles.asset_content}>
      <a className={styles.asset_url} href={url} target="_blank" rel="noreferrer">
        <div className={styles.svg_container}>
          <svg width="24" height="22" viewBox="0 0 34 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.5 17.9925V0H18.5V18.0198L24.2607 12.2591L26.382 14.3804L17.0137 23.7488L7.63245 14.3675L9.75378 12.2462L15.5 17.9925ZM3.5 27.5V21H0.5V30.5H33.5V21H30.5V27.5H3.5Z" fill="#5F6A7D"/>
          </svg>  
        </div>
        {name}
      </a>
    </div>
  );
}