import { useEffect, useState } from "react";
import styles from "./DynamicForm.module.scss";
import { IField } from "./subscribe.form";

export default function AcceptTermCheckbox({
  label,
  customTerms,
  ...inputProps
}: any) {
  const [checkboxStatus, setCheckboxStatus] = useState(false);

  return (
    <div className={styles.form_checkbox}>
      <label className={styles.form_terms}>
        <input
          type="checkbox"
          className={styles.form_checkbox_input}
          {...inputProps}
          onClick={() => setCheckboxStatus(!checkboxStatus)}
        />
        {checkboxStatus ? (
          <>
            <span className={styles.checked}></span>
            <span className={styles.checkedBg}></span>
          </>
        ) : (
          <span className={styles.checkmark}></span>
        )}
        <span className={styles.form_terms_text}>
          Ao prosseguir, seus dados serão compartilhados com Mary Kay e você
          concordará com nossos
          <a href="https://docs.google.com/a/eventials.com/viewer?embedded=true&url=https://s3.amazonaws.com/eventials-contratos/Eventials-Termos_Gerais_de_Uso-v1.pdf" target="_blank" className={styles.form_title_link} rel="noreferrer">Termos de Uso</a> e
          <a href="https://www.eventials.com/portal-de-privacidade/" target="_blank" className={styles.form_title_link} rel="noreferrer">
            Política de Privacidade
          </a>
          {customTerms &&customTerms.map((term: IField, index: number) => {
            let htmltext = term.label.replace(/\[(.+?)\]\((.+?)\)/g, `<a target="_blank" class=${styles.form_title_link} rel="noreferrer" href="$2">$1</a>`);
            return <span style={{marginLeft: '3px'}} className={styles.form_terms_text} key={index} dangerouslySetInnerHTML={{ __html: htmltext }} />;
          })}
        </span>
      </label>
    </div>
  );
}
