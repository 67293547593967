import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";

import TermsInput from "./accept.input";
import GroupInput, { GroupInputMask, SelectInput } from "./input";

import styles from "./DynamicForm.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import userStorage from "../../lib/storage/user";
import authApi from "../../lib/api/auth";

import 'react-toastify/dist/ReactToastify.css';

export interface IField {
  additional_data?: any;
  dependent?: {
    disabled: string,
    field: string
  },
  is_active: boolean,
  placeholder_pt_br: string,
  type: string,
  placeholder: string,
  placeholder_en: string,
  name: string,
  label_pt_br: string,
  default: string,
  label_es: string,
  required: false,
  value: string,
  label: string,
  label_en: string,
  placeholder_es: string
}

interface IRequestForm {
  id: number;
  value: any;
}

export default function SubscribeForm({
  event,
  handleChange,
  values,
}: any) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [hasCustomForm, setHasCustomForm] = useState(false);
  const [hasAccessCode, setHasAccessCode] = useState(false);
  const [text, setText] = useState({
    title: "Carregando...",
    date: "Em breve",
  });
  const { owner, slug } = useParams();
  const customForm = event.webinar.custom_form;

  useEffect(() => {

    if (customForm) setHasCustomForm(true);
    else setHasCustomForm(false);

    if (!event) return;

    if (event) {
      const loggedUser = userStorage.load(slug as string);
      if (loggedUser && loggedUser.name && loggedUser.email) {
        navigate(`/${owner}/${slug}/assistir`);
        return;
      }
    }

    let title = "";
    let date = "Em breve";

    if (event) {
      const d = new Date(event.webinar.start_time);
      date = d.toLocaleDateString("pt-BR", {
        day: "numeric",
        month: "long",
        year: "numeric",
        weekday: "long",
      });
      date += ` às `;
      date += d.toLocaleTimeString("pt-BR", {
        hour: "numeric",
        minute: "numeric",
      });
      title = event.webinar.title;
    }

    setText({ title, date });
  }, [])

  const generateCustomField = (field: any) => {
    if (field.type === 'number') {
      return (
        <GroupInputMask
          mask="999999999999"
          label={field.label}
          name={field.name}
          type="tel"
          placeholder={field.placeholder}
          onChange={handleChange(field.name)}
          value={values[field.name]}
          key={field.name}
          disabled={field.dependent && values[field.dependent.field] !== field.name}
        />
      );
    }
    else if (field.type === 'cpf') {
      return (
        <GroupInputMask
          mask="999.999.999-99"
          label={field.label}
          name={field.name}
          type="tel"
          placeholder={field.placeholder}
          onChange={handleChange(field.name)}
          value={values[field.name]}
          key={field.name}
          disabled={field.dependent && values[field.dependent.field] !== field.name}
        />
      );
    }
    else if (field.type === 'phone') {
      return (
        <GroupInputMask
          mask="(99)99999-9999"
          label={field.label}
          name={field.name}
          type="tel"
          placeholder={field.placeholder}
          onChange={handleChange(field.name)}
          value={values[field.name]}
          key={field.name}
          disabled={field.dependent && values[field.dependent.field] !== field.name}
        />
      );
    }
    else if (field.type === 'email') {
      return (
        <GroupInput
          label={field.label}
          name={field.name}
          type="email"
          placeholder={field.placeholder}
          onChange={handleChange(field.name)}
          value={values[field.name]}
          key={field.name}
          disabled={field.dependent && values[field.dependent.field] !== field.name}
        />
      );
    }
    else if (field.type === 'cnpj') {
      return (
        <GroupInputMask
          mask="99.999.999/9999-99"
          label={field.label}
          name={field.name}
          type="tel"
          placeholder={field.placeholder}
          onChange={handleChange(field.name)}
          value={values[field.name]}
          key={field.name}
          disabled={field.dependent && values[field.dependent.field] !== field.name}
        />
      );
    }
    else if (field.type === 'cep') {
      return (
        <GroupInputMask
          mask="99999-999"
          label={field.label}
          name={field.name}
          type="tel"
          placeholder={field.placeholder}
          onChange={handleChange(field.name)}
          value={values[field.name]}
          key={field.name}
          disabled={field.dependent && values[field.dependent.field] !== field.name}
        />
      );
    }
    else if (field.type === 'date') {
      return (
        <GroupInputMask
          mask="99/99/9999"
          label={field.label}
          name={field.name}
          type="text"
          placeholder={field.placeholder}
          onChange={handleChange(field.name)}
          value={values[field.name]}
          key={field.name}
          disabled={field.dependent && values[field.dependent.field] !== field.name}
        />
      );
    }
    else if (field.type === 'password') {
      return (
        <GroupInput
          label={field.label}
          name={field.name}
          type="password"
          placeholder={field.placeholder}
          onChange={handleChange(field.name)}
          value={values[field.name]}
          key={field.name}
          disabled={field.dependent && values[field.dependent.field] !== field.name}
        />
      );
    }
    else if (field.type === 'select') {
      let optionsParse = field.label.split(';');

      let jsonOptions = optionsParse.reduce((options: any, opt: any) => {
        let option = null;

        try {
          option = JSON.parse(opt);
          option.label = option.label.trim();
          option.value = option.value.trim();
        } catch (err) {
          console.warn('generateSelect got an JSON.parse issue');
        }

        if (option) {
          options.push(option);
        }

        return options;
      }, []);

      return (
        <SelectInput
          name={field.name}
          onChange={handleChange(field.name)}
          value={values[field.name]}
          options={jsonOptions}
          placeholder={field.placeholder}
          disabled={field.dependent && values[field.dependent.field] !== field.name}
        />
      );
    }
    else if (field.type !== 'privacy_policy') {
      return (
        <GroupInput
          label={field.label}
          name={field.name}
          type="text"
          placeholder={field.placeholder}
          onChange={handleChange(field.name)}
          value={values[field.name]}
          disabled={field.dependent && values[field.dependent.field] !== field.name}
        />
      );
    }
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    handleSubscribe();
  };

  const handleAccept = (e: any) => {
    e.target.value = e.target.checked;
    const customTerm = customForm && customForm.find((field: IField) => field.type === 'privacy_policy');
    if (customTerm) {
      handleChange("terms", customTerm.name)(e);
    }
    else {
      handleChange("terms")(e);
    }
  };

  const handleSubscribe = async () => {

    if (loading) return;

    Object.keys(values).map(k => values[k] = values[k].trim());

    const emptyFields = getEmptyFields(values);
    const emptyFieldsIsAccessCode = emptyFields.includes('access_code');

    if (emptyFields.length > 0 && !emptyFieldsIsAccessCode) {
      toast.error("Preencha todos os campos para continuar");
      return;
    }

    if (!isValidEmail(values.email)) {
      toast.error("Preencha um email válido!");
      return;
    }

    const hasWrongValues = validFieldsValues(customForm)

    if (hasWrongValues.length > 0) {
      toast.error("Preencha todos os campos corretamente para continuar");
      return;
    }


    if (!values.terms) {
      toast.error("É necessário concordar com os termos de uso");
      return;
    }

    const reqValues: IRequestForm[] = [];

    if (customForm) {
      for (const [name, value] of Object.entries(values)) {
        const field = customForm.find((field: IField) => field.name === name);
        if (field) {
          reqValues.push({
            id: field.id,
            value: value
          });
        }
      }
    }

    try {
      setLoading(true);
      const userData = await authApi.subscribe({
        // form_values: reqValues,
        owner: owner,
        slug: slug,
        email: values.email,
        ...!event.webinar.is_public ? { access_code: values.access_code } : {}
      },
        event.webinar.is_public
      );

      if (userData === 204 && emptyFieldsIsAccessCode) {
        setHasAccessCode(true);
        toast.warning("Digite o código de acesso para continuar.");
        return;
      }


      if (userData === 404) {
        toast.error("E-mail incorreto ou não cadastrado.");
        return
      }

      if (userData && userData.success) {
        userStorage.save(userData, `${slug}`);
        navigate(`/${owner}/${slug}/assistir`);
      } else {
        throw new Error();
      }

    } catch (err) {
      toast.error("Tente novamente mais tarde.");
    } finally {
      setLoading(false);
    }
  };

  const validFieldsValues = (form: any) => {
    let hasWrongValues: Array<any> = [];

    if (!customForm) return hasWrongValues;

    customForm.forEach((field: any) => {
      if (field.type === 'text' && (
        field.name.toLowerCase().includes('nome') ||
        field.name.toLowerCase().includes('name')
      )) {
        if (!values[field.name].includes(' ')) {
          toast.error("Preencha seu nome e sobrenome.");
          hasWrongValues.push(field.name)
          return false;
        }
      }
      else if (field.type === 'email') {
        if (!isValidEmail(values[field.name])) {
          toast.error("Preencha um email válido!");
          hasWrongValues.push(field.name)
          return false;
        }
      }
      else if (field.type === 'cpf' && field.required) {
        if (!isValidCPF(values[field.name])) {
          toast.error("CPF inválido!");
          hasWrongValues.push(field.name)
          return false;
        }
      }
      else if (field.type === 'phone' || field.type === 'number') {
        values[field.name] = values[field.name].replace(/_/g, ''); //removendo os _ que a mascara cria
      }

      if (field.required) {
        const value = values[field.name];
        if ((!value || `${value}`.trim().length === 0) && field.type !== 'privacy_policy') {
          toast.error(`${field.type === 'select' ? field.name : field.label} é um campo obrigatório.`);
          hasWrongValues.push(field.name);
          return false;
        }
      }
    });

    return hasWrongValues;
  }

  const getEmptyFields = (values: any) => {
    let emptyFields: Array<any> = [];
    Object.keys(values).forEach((k) => {
      if (values[k] === "") {
        emptyFields.push(k);
      }
    });
    return customForm ?
      emptyFields.filter(emptyField => !customForm.find((field: any) => field.name === emptyField && !field.required)) :
      emptyFields;
  };

  const isValidCPF = (strCPF: string) => {
    strCPF = strCPF.replace(/\./g, '').replace(/\-/g, '');
    var soma;
    var resto;
    soma = 0;
    if (strCPF === "00000000000" ||
      strCPF === "11111111111" ||
      strCPF === "22222222222" ||
      strCPF === "33333333333" ||
      strCPF === "44444444444" ||
      strCPF === "55555555555" ||
      strCPF === "66666666666" ||
      strCPF === "77777777777" ||
      strCPF === "88888888888" ||
      strCPF === "99999999999") return false;

    for (let i = 1; i <= 9; i++)
      soma = soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(strCPF.substring(9, 10))) return false;

    soma = 0;
    for (let i = 1; i <= 10; i++)
      soma = soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(strCPF.substring(10, 11))) return false;
    return true;
  };

  const isValidEmail = (email: string) => {
    if (!email) return false;
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };


  return (
    <div className={`${styles.container} slide-from-right`}>
      <ToastContainer />

      <div className={`${styles.form} ${styles.form_subscribe}`}>

        <div className={styles.description}>
          <h1 className={styles.description_name}>{text.title}</h1>
          <p className={styles.description_date}>{text.date}</p>
        </div>

        <form className={styles.form_inputs} onSubmit={handleSubmit}>
          <GroupInput
            name="input-email"
            label="E-mail"
            type="email"
            placeholder="Informe o seu e-mail"
            onChange={handleChange("email")}
            value={values.email}
          />
          {!event.webinar.is_public && hasAccessCode && (
            <GroupInput
              label="Código de acesso"
              value={values?.access_code}
              placeholder="Informe o seu código de acesso"
              onChange={handleChange("access_code")}
            />
          )}
          {hasCustomForm && customForm.map((field: any) => <div key={field.label}>{generateCustomField(field)}</div>)}
          <TermsInput
            onChange={handleAccept}
            customTerms={hasCustomForm && customForm.filter((field: IField) => field.type === 'privacy_policy')}
          />

          <div className={styles.button_container}>
            <button
              type="submit"
              className={`${styles.btn} ${styles.btn_lg} ${styles.btn_primary}`}
              id="btn-register"
            >
              {loading ? "AGUARDE" : "Participar"}

            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
